import { Button } from '@mui/material'
import React from 'react'
import { FACEBOOK_BLACK } from '../../assets/colors/Colors'
import { SubtitleIIRsp } from '../../locust/components/Auth'
import { ShopBulletText } from './ShopBullets'

const ShopRoundedButton = ( props : { children ?: any }) => {
    return (
        <>
            <Button 
                style={{ 
                    backgroundColor : FACEBOOK_BLACK, 
                    width : 180, 
                    borderRadius : 50, 
                    height : 40 
                }} 
                variant="contained"> 
                <ShopBulletText style={{ fontSize : 14 }}>
                    {props.children}
                </ShopBulletText>
            </Button>
        </>
    )
}


export default ShopRoundedButton;