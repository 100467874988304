import React from 'react'
import { getFontMontserrat } from '../../assets/fonts/FontMontserrat'
import { getFontRoboto } from '../../assets/fonts/FontRoboto'
import { css } from 'glamor'
import { getConfigDefaults } from '../../locust/composables/Layout'
import { ScaleRsp } from '../../locust/composables/Scale'
import SpacerV from '../../locust/sections/SpacerV'

const titles = {
    intial : {
        title : "Finally, a Business Card",
        subtitle : "that means business",
    },
    demoNFC : {
        title : "Never Buy Business Cards Again",
        subtitle : "Full Reusablity",
    },
    customize : {
        title : "Customize and Stylize",
        subtitle : "Fully Customizable",
    },
    environmental : {
        title : "Seven Million",
        subtitle : "trees are cut down every year just to make business cards",
    },
    rubbish : {
        title : "Ninety Percent",
        subtitle : "Of business cards End up in the trash. What kind of rubbish is that?",
    }
}



export const LandingPageLandingSection = ( props : { 
    children ?: any 
    title ?: string | React.ReactNode
    subtitle ?: string | React.ReactNode
    setTitle ?: ( value : string | React.ReactNode ) => void 
    setSubtitle ?: ( value : string | React.ReactNode ) => void 
}) => {

    const title : string | React.ReactNode = props.title && typeof props.title !== "string" 
        ? props.title
        :  <TitleLarge>{props.title ? props.title : ""}</TitleLarge>
    
    const subtitle : string | React.ReactNode = props.subtitle && typeof props.subtitle !== "string" 
        ? props.subtitle
        :  <SubtitleLarge>{props.subtitle ? props.subtitle : ""}</SubtitleLarge>

    const [cardAnimationComplete, setCardAnimationComplete] = React.useState(false)

    const linhStyle : Record<string,any>= {
        width : 'calc(100% - 0px)', 
        marginLeft : 0, 
        marginTop : 10, 
        boxShadow: `rgba(0, 0, 0, 0.10) 0px 5px 15px`,
        borderRadius : 10, 
    }

    return (
        <>
                
               
                
                
                <div style={{ 
                    width : 500, 
                    marginLeft : 'calc(100% - 400px - 500px - 5vw - 5vw)',
                    height : 500,  
                    verticalAlign : 'top', 
                    backgroundColor : 'transparent', 
                    borderRadius : 20, 
                    display : 'inline-block',
                    border : '1px solid blue'
                }}>
                    {props.children}
                </div>
                </>
                
                
            
           
        
    )

}

export const TitleLarge = ( props : { children ?: any }) => {
    return (
        <h1 {...css({
            ...getFontMontserrat(),
            textTransform : 'uppercase',
            color : 'rgba(0,0,0,0.8)',
            fontSize : 40,
            fontWeight  : 900
        })}>
            {props.children}
        </h1>
    )
}

export const SubtitleLarge = ( props : { children ?: any, style ?: Record<string,any> }) => {
    return (
        <h1 {...css({
            ...getFontRoboto(),
            //textTransform : 'uppercase',
            lineHeight : 1.25,
            color : 'rgba(0,0,0,0.8)',
            fontSize : 20,
            fontWeight  : 400,
            letterSpacing : 2.0,
            ...props.style,
        })}>
            {props.children}
        </h1>
    )
}


