import * as React from "react"
import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css'
import { AnimEnvironmental } from "../client-ui/checkout/AnimEnvironmental";
import { AnimCardSlide } from "../client-ui/checkout/AnimCardSlide";
import CobraLayout from "../client-ui/shared/LayoutCobra";
import { LineDividerSpacer } from "../locust/components/LineDivider";
import X from "../locust/composables/X";
import SpacerV from "../locust/sections/SpacerV";
import { css } from "glamor";
import PrimaryTitleSubtitle from "../client-ui/shared/PrimaryTitleSubtitle";
import ShopLandingSection from "../client-ui/shop/ShopLandingSection";
import { BulletSubtitle } from "../client-ui/shop/ShopBullets";
import { ShopItemExample2, ShopItemExample4, ShopItemExample5_SubscriptionStandalone } from "../client-ui/shop/ShopItemBox";
import Carousel from 'react-multi-carousel';
import ShopRoundedButton from "../client-ui/shop/ShopRoundedButton";
import AuthManager from "../locust/composables/AuthManager";




const Page = ( ) => {
    
    const [loggedIn, setLoggedIn ] = React.useState(false)

    return (
        <>  
            {/* <ClientFonts/> */}
            <AuthManager noredirect setLoggedIn={setLoggedIn}/>
            <CobraLayout headerType="store" loggedIn={loggedIn}>
                <LandingSection/>
                <LineDividerSpacer top={0} bottom={130}/>
                <PrimaryTitleSubtitle>
                    <p>A Business Card</p>
                    <p>That Really Means Business</p>
                </PrimaryTitleSubtitle>
                <SpacerV h={80}/>
                <ProductSectionExample/>
                <LineDividerSpacer top={50} bottom={30}/>
                <ProductSectionExample/>
                

                <LineDividerSpacer top={30} bottom={130}/>
                <PrimaryTitleSubtitle>
                    <p>Track Leads in Realtime</p>
                    <p>That Really Means Business</p>
                </PrimaryTitleSubtitle>
                <SpacerV h={80}/>
                <ProductSectionExample/>
                <LineDividerSpacer top={50} bottom={30}/>
                <ProductSectionExample/>



                <LineDividerSpacer top={30} bottom={130}/>
                <PrimaryTitleSubtitle>
                    <p>Social Media Simplified</p>
                    <p>No more searching. Just Tap to Connect</p>
                </PrimaryTitleSubtitle>
                <SpacerV h={80}/>
                <ProductSectionExample/>
                <LineDividerSpacer top={50} bottom={30}/>
                <ProductSectionExample/>


                <LineDividerSpacer top={30} bottom={130}/>
                <PrimaryTitleSubtitle>
                    <p>Standalone Skins</p>
                    <p>Want a Fresh Look? Check Out These Babies.</p>
                </PrimaryTitleSubtitle>
                <SpacerV h={80}/>
                <ProductSectionExample title="Most Popular Skins"/>
                <LineDividerSpacer top={50} bottom={30}/>
                <ProductSectionExample/>


                <SpacerV h={200}/>
            </CobraLayout>
        </>
    )
}
export default Page

const LandingSection = () => {
    const [ title, setTitle ] = React.useState<string|React.ReactNode>("")
    const [ subtitle, setSubtitle ] = React.useState<string|React.ReactNode>("")
    const [ carouselIndex, setCarouselIndex ] = React.useState(0)
    const carousel : React.ReactNode[] = [
        <AnimEnvironmental 
            setTitle={setTitle} 
            setSubtitle={setSubtitle}
            setAnimationCompleteDelay={3000}
            setAnimationComplete={()=>{ setCarouselIndex(1) }}
        />,
        <AnimCardSlide 
            setTitle={setTitle} 
            setSubtitle={setSubtitle}
            setAnimationCompleteDelay={1000}
            setAnimationComplete={()=>{ setCarouselIndex(0) }}
        />,
    ]

    return (
        <>
            <ShopLandingSection title={title} subtitle={subtitle}>
                {carousel[carouselIndex]}
            </ShopLandingSection>
        </>
    )
}



const ProductSectionExample = ( props : { title ?: string }) => {
    return (
        <>  
            <PopularProducts title={props.title}>
                <ShopItemExample5_SubscriptionStandalone/>
                <ShopItemExample4/>
                <ShopItemExample4/>
                <ShopItemExample4/>
                <ShopItemExample4/>
                <ShopItemExample4/>
                <ShopItemExample4/>
                <ShopItemExample4/>
                <ShopItemExample4/>
            </PopularProducts>
        </>
    )
}

export const PopularProducts = ( props : { children ?: any, title ?: string }) => {
    return (
        <>
         <ProductsContainer>
                <BulletSubtitle>
                    { props.title  ? props.title : 'Most Popular Styles' }
                    
                </BulletSubtitle>
                <SpacerV h={30}/>
                <Carousel 
                    infinite={true}
                    responsive={getProductCarouselParams()}>
                        { props.children}
                </Carousel>
                <SpacerV h={30}/>
                <X style={{ height : 60 }}>
                    <ShopRoundedButton>
                        See More
                    </ShopRoundedButton>
                </X>
                  
                
            </ProductsContainer>
        </>
    )

}


export const ProductsContainer = ( props : { children ?: any } ) => {
    
    return (
        <>
            <SpacerV h={40}/>
            
            <div {...css({ paddingLeft: 0, textAlign : 'center' }) }>
                
                {props.children}
            </div>
        </>
    )
}


const getProductCarouselParams = () => {
    return {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1300 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1300, min: 960 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 960, min: 650 },
            items: 2
        },
        min: {
            breakpoint: { max: 650, min: 0 },
            items: 1
        }
    };
}
