import { css } from 'glamor';
import React, { useEffect, useState } from 'react'
import { getConfigDefaults } from '../../locust/composables/Layout';
import { ScaleRsp } from '../../locust/composables/Scale';
import { PrimarySubtitleLarge, PrimaryTitleLarge } from '../shared/PrimaryTitleSubtitle';
import X from '../../locust/composables/X';
import { FACEBOOK_BLUE } from '../../assets/colors/Colors';
import { CircularProgress } from '@mui/material';
import { createGlobalStyle } from 'styled-components';




const GlobalStyle = createGlobalStyle`
*{
  margin:0;
  padding:0;
}

html, body {
    width : 100vw;
    background-color : white;
    -webkit-overflow-scrolling: touch;
}

body {
  position: relative;
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: visible;

}

img{
  max-width:100%;
}
`;


export default ( props: { 
    children ?: any, 
    title ?: string|React.ReactNode, 
    subtitle?: string|React.ReactNode  
}) => {
    return (
        <div style={{ minWidth : 350, width : '100vw'}}>
            <GlobalStyle/>
            <Desktop>
                {props.children}
            </Desktop>
        </div>
        
    )
}


const Desktop = ( props: { children ?: any }) => {
    
    const [ loaded, setLoaded ] = useState( false )

    useEffect(()=>{
        setLoaded(true)
    },[])
   
    
    
    if(!loaded) return <X><CircularProgress size={50} style={{ marginLeft : 70, marginTop : 400, color : FACEBOOK_BLUE }}/></X>

    return (
        <div style={{ width : '100%', textAlign : 'center'}}>
            
        </div>
    )
}














const ShopLandingSection = ( props : { 
    title : string|React.ReactNode,
    subtitle : string|React.ReactNode, 
    children ?: any 
}) => {
    
    const min = 350
    const left : number = 400;
    const right : number = 560;
    const config = getConfigDefaults()
    const on = ( value : number ) => { return config.onMedia( value )}
    
    const DEBUG : boolean = false

    const scales = [
        { width : 2000, value : 1.0},
        { width : 1000, value : 0.88},
        { width : 900, value : 0.80},
        { width : 790, value : 1.0 },
    ]  

    const title : string | React.ReactNode = props.title && typeof props.title !== "string" 
    ? props.title
    :  <PrimaryTitleLarge>{props.title ? props.title : ""}</PrimaryTitleLarge>

    const subtitle : string | React.ReactNode = props.subtitle && typeof props.subtitle !== "string" 
        ? props.subtitle
        :  <PrimarySubtitleLarge>{props.subtitle ? props.subtitle : ""}</PrimarySubtitleLarge>
    
    return (
        <>
            <div
                {...css({ 
                    width : '100%', 
                    maxWidth : 1280, 
                    height : 800, 
                    overflow : 'hidden', 
                    border : DEBUG ? '1px solid orange' : '',
                    marginBottom : - 170,
                    [ on(450)  ]:{ 
                        marginBottom : -290,
                    },
                })}
            >
            <div {...css({ 
                width : left, 
                height : 'auto', 
                verticalAlign : 'top', 
                float : 'left',
                display : 'inline-block',
                border : DEBUG ? '1px solid red' : '' ,
                [ on(1000) ] : {
                    width : 400*0.88,
                },
                [ on(900) ] : {
                    width : 280,
                },
                [ on(790)  ]:{ 
                    clear : 'both',
                    width : '100%',
                    marginBottom : -200,
                    marginTop : -70,
                },
                [ on(450)  ]:{ 
                    marginTop : -50,
                },
                
                
            })}>
                    <div {...css({
                        border : DEBUG ? '1px solid purple' : '',
                        marginLeft : 60,
                        [ on(1000) ] : {
                            marginLeft : 100,
                        },
                        [ on(900) ] : {
                            marginLeft : 20,
                        },
                        [ on(790) ] : {
                            width : '100%',
                            marginLeft : 'calc(50% - 180px)',
                        },
                        [ on(450) ] : {
                            marginLeft : 'calc(50% - 150px)',
                        },

                        

                    })}>
                    <ScaleRsp 
                        scales={[
                            ...scales,
                            { width : 700, value : 0.8 },
                        ]}>
                            <div {...css({ 
                                 width : left, 
                                 height : 500, 
                            })}>        
                                <>
                                    { title     }
                                    { subtitle  }
                                </>
                            </div>
                    </ScaleRsp>
                    </div>
            </div>

            <div {...css({ 
                width : right, 
                height : 'auto', 
                float : 'right',
                verticalAlign : 'top', 
                display : 'inline-block',
                border : DEBUG ? '1px solid blue' : '',
                [ on(1000) ]:{ 
                    width : 500,
                 },
                [ on(790)]: {
                    float : 'left',
                    width : '100%',
                },
                [ on( 450 ) ]:{
                    width : min,
                    height : min,
                    marginLeft : 'calc(50% - 136px)',
                    marginTop : -70,
                } 
                
                })}>
                    
                    <div {...css({  
                        [ on(900) ] : {
                            marginLeft : 50,
                        },
                        [ on(790) ] : {
                            marginLeft : 'calc(50% - 280px)',
                        },
                        [ on(600) ] : {
                            marginLeft : 'calc(50% - 210px)',
                        }
                    })}
                    >
                                
                    
                        
                        <ScaleRsp scales={[
                            ...scales,
                            { width : 600, value : 0.75 },
                            { width : 450, value : 0.6 },
                        ]}>
                
                                
                                <div {...css({  
                                    width : 500, 
                                    height : 500, 
                                    border : DEBUG ? '1px solid lime' : '',
                                    overflow : 'hidden',
                                })}
                                >
                                    {props.children}
                                </div>
                                
                                
                            
                        </ScaleRsp>
                        
                    </div>
            </div>
            </div>
        </>
    )
}

